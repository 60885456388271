import React from "react";
import "./App.scss";
import Logo from "./components/logo/Logo";

function App() {
  return (
    <div className="App">
 
      <div className="">
        <div className="content">
          <Logo/>
          <div className="text-xs text-center text-gray-600">
            {"Website under construction"} <br/>
            {"(Current commit: <GITCOMMITHASH>)"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
