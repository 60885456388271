import React from "react";
import icon from "./icon.png";

function Logo() {
  return (
    <div className="text-center">
        <img className="h-36 m-auto inline" alt="" src={icon} style={{transform: "scale(1.4)"}}/>
    </div>
  );
}

export default Logo;
